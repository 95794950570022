//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import gtmMixin from '~/mixins/gtm';
import BasePricing from '~/components/product-card/BasePricing.vue';

const DEFAULT_IMAGE = '/images/placeholder.jpg';

export default {
  components: {
    BasePricing,
  },
  props: {
    webProductId: {
      type: String,
      required: true,
    },
    sku: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    algoliaType: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      required: false,
    },
    imageId: {
      type: String,
      required: false,
    },
    imageFormat: {
      type: String,
      required: false,
      default: 'half',
    },
    imageTwoId: {
      type: String,
      required: false,
    },
    imageTwoFormat: {
      type: String,
      required: false,
      default: 'half',
    },
    imageUrl: {
      type: String,
      required: false,
    },
    imageUrlWebp: {
      type: String,
      required: false,
    },
    imageTwoUrl: {
      type: String,
      required: false,
    },
    imageTwoUrlWebp: {
      type: String,
      required: false,
    },
    clickCallBack: {
      default: null,
      type: Function,
    },
    categories: {
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
    priceQuery: {
      type: String,
    },
    limitedStock: {
      type: Boolean,
    },
    specialOrder: {
      type: Boolean,
    },
    isClearance: {
      type: Boolean,
    },
    basePricing: {
      type: Object,
    },
    objectID: {
      type: String,
    },
  },
  mixins: [gtmMixin],
  data() {
    return {
      defaultImage: DEFAULT_IMAGE,
      isFetchingPrice: false,
      hasBulkBuy: false,
      customPricing: undefined,
      basePricingError: false,
      banners: [
        {
          key: 'limitedStock',
          translationKey: 'product.limited_stock.banner',
        },
        {
          key: 'specialOrder',
          translationKey: 'product.special_order.banner',
        },
        {
          key: 'isClearance',
          translationKey: 'product.clearance.banner',
        },
        {
          key: 'hasBulkBuy',
          translationKey: 'product.bulk_buy.banner',
        },
      ],
    };
  },
  watch: {
    isUserLoggedIn: {
      immediate: true,
      async handler(value) {
        if (value && this.isUserLoggedIn) {
          if (this.isFetchingPrice || this.hasCustomPricing) return;
          await this.fetchCustomPrice();
        }
      },
    },
  },
  computed: {
    hasProductLoaded() {
      return this.isFetchingPrice === false && this.hasValidPricing;
    },
    getAlgoliaType() {
      const allowed = ['together', 'related', 'trending'];
      return allowed.includes(this.algoliaType) ? this.algoliaType : 'related';
    },
    hasImageOne() {
      return this.imageUrl || this.imageUrlWebp;
    },
    hasImageTwo() {
      return this.imageTwoUrl || this.imageTwoUrlWebp;
    },
    imageOneSource() {
      if (this.imageUrlWebp) return this.imageUrlWebp;
      return this.imageUrl;
    },
    imageTwoSource() {
      if (this.imageTwoUrlWebp) return this.imageTwoUrlWebp;
      return this.imageTwoUrl;
    },
    imageOneType() {
      if (this.imageUrlWebp) return 'image/webp';
      return 'image/png';
    },
    imageTwoType() {
      if (this.imageTwoUrlWebp) return 'image/webp';
      return 'image/png';
    },
    hasSkuRetail() {
      if (!this.basePricing?.skuRetailIncGst) return false;
      const skuPrice = this.basePricing.skuRetailIncGst;
      return !isNaN(skuPrice) && skuPrice > 0;
    },
    hasLowestPrice() {
      if (!this.basePricing?.webProductLowest?.price) return false;
      const skuPrice = this.basePricing.webProductLowest.price;
      return !isNaN(skuPrice) && skuPrice > 0;
    },
    hasBasePricingDiscountFlag() {
      return this.basePricing && this.basePricing.hasBulkDiscounts === true;
    },
    hasCustomPricing() {
      return (
        this.customPricing &&
        this.customPricing.unitPrice &&
        this.isValidPriceNumber(this.customPricing.unitPrice)
      );
    },
    hasValidPricing() {
      if (!this.basePricing && this.priceQuery) return true;
      if (this.basePricing) return true;

      return false;
    },
    canShowBasePricing() {
      const canshow =
        this.sku &&
        this.basePricing &&
        (this.hasSkuRetail || this.hasLowestPrice);

      if (canshow) {
        if (!this.isUserLoggedIn) return true;
        if (this.isUserLoggedIn && this.hasCustomPricing) return true;
      }

      return false;
    },
    filteredBanners() {
      let bannerToShow = null;
      for (const banner of this.banners) {
        if (this[banner.key] && this.$t(banner.translationKey)) {
          bannerToShow = banner;
          break;
        }
      }

      if (this.hasBasePricingDiscountFlag && !bannerToShow) {
        bannerToShow = this.banners.find(banner => banner.key === 'hasBulkBuy');
      }

      return bannerToShow ? [bannerToShow] : [];
    },
    isUserLoggedIn() {
      return this.$store.getters['users/getIsLoggedIn'];
    },
    imageIdUrl() {
      const imageId = this.imageId;
      const bucketDomain = this.$config.storageBase ?? null;
      if (imageId && bucketDomain) {
        // default erp images are found at: htts://{bucket-domain}/media/internal/{imageId}/600.webp
        return `${bucketDomain}/media/internal/${imageId}/600.webp`;
      }

      return undefined;
    },
    usePromotionPrices() {
      // if can show base pricing, and product is in clearance, use promotion prices
      return this.canShowBasePricing && this.isClearance;
    },
  },
  methods: {
    async handleClick() {
      const category = await this.getCategory(this.categories);
      const list = this.getPathName;
      const event = {
        event: 'eec.impressionClick',
        ecommerce: {
          click: {
            actionField: {
              list,
            },
            products: [
              {
                name: this.title,
                id: this.webProductId,
                category,
                position: this.position,
              },
            ],
          },
        },
      };

      this.$gtm.push(event);
      if (this.clickCallBack) this.clickCallBack();
    },
    isValidPriceNumber(value) {
      return value && typeof value === 'number' && value > 0;
    },
    async fetchCustomPrice() {
      if (this.isFetchingPrice || !this.isUserLoggedIn) return;
      this.isFetchingPrice = true;
      this.basePricingError = false;

      // fetch the custom price for this user
      // if we are displaying the "from/lowest" price, we need to get the webProductLowest.sku
      // otherwise, we need to get the base sku
      const sku = this.hasLowestPrice
        ? this.basePricing.webProductLowest.sku
        : this.sku;

      // check user admin permissions
      // if "showCustomerPrice" is defined, and equals FALSE, do not fetch custom pricing
      const userContact = this.$store.getters['users/getContact'];
      if (
        userContact &&
        userContact.permissions &&
        userContact.permissions.rules
      ) {
        if (
          typeof userContact.permissions.rules.showCustomerPrice === 'boolean'
        ) {
          if (userContact.permissions.rules.showCustomerPrice === false) {
            const price = this.hasLowestPrice
              ? this.basePricing.webProductLowest.price
              : this.basePricing.skuRetailIncGst;
            this.customPricing = {
              sku,
              unitPrice: price,
              baseUnitPrice: price,
            };
            this.isFetchingPrice = false;
            return;
          }
        }
      }

      // state pricing/getPricingById
      const response = await this.$store.dispatch('pricing/getPricingById', {
        id: sku,
        qty: 1,
      });

      if (response && response.unitPrice) {
        const customPricing = {
          sku,
          unitPrice: response.unitPrice,
          lineGst: response.lineGst,
          linePrice: response.linePrice,
          baseUnitPrice: response.baseUnitPrice,
          baseLinePrice: response.baseLinePrice,
          baseLineGst: response.baseLineGst,
          customerUnitGst: response.customerUnitGst,
          qty: response.qty,
        };
        this.customPricing = customPricing;
        this.isFetchingPrice = false;
      } else {
        // user is logged in and custom pricing is unavailable, end loading and show nothing
        this.basePricingError = true;
        this.isFetchingPrice = false;
      }

      this.isFetchingPrice = false;
      return;
    },
    setHasBulkBuyFlag(value) {
      this.hasBulkBuy = value;
    },
  },
  async created() {
    // console.log(this.sku, 'Product Card - Created');
    if (this.isUserLoggedIn) {
      // console.log(this.sku, 'User is Logged In - Fetch Custom Price');
      await this.fetchCustomPrice();
    }
  },
};
