import usersActions from './users/actions';
import usersApi from './users/api';
import usersUtils from './users/utils';
import { HYDRATION_STATUS } from './users/utils';

export const state = () => ({
  hydrationStatus: HYDRATION_STATUS.pending,
  customer: {},
  contact: {},
  statement: {},
  balance: {},
  temps: {
    companies: [],
    companyToken: {},
    twoFactorToken: {},
  },
  tokenExpirations: {
    refreshTokenExpires: undefined,
    accessTokenExpires: undefined,
  },
  forcedLogout: false,
  isRefreshing: false,
});

export const utils = usersUtils;

export const getters = {
  getUser: state => {
    return state;
  },
  getHydrationStatus: state => {
    return state.hydrationStatus;
  },
  getTempCompanyToken: state => {
    return state.temps.companyToken;
  },
  getTempCompanies: state => {
    return state.temps.companies;
  },
  getTempTwoFactorToken: state => {
    return state.temps.twoFactorToken;
  },
  getCustomer: state => {
    return state.customer;
  },
  getIsLoggedIn: state => {
    return Object.keys(state.customer).length ? true : false;
  },
  getContact: state => {
    return state.contact;
  },
  getStatementDetails: state => {
    return state.statement;
  },
  getBalanceDetails: state => {
    return state.balance;
  },
  getTokenExpirations: state => {
    return state.tokenExpirations;
  },
  getForcedLogout: state => {
    return state.forcedLogout;
  },
  getIsRefreshing: state => {
    return state.isRefreshing;
  },
  getFrameworksDetails: state => {
    return state.frameworks;
  },
  getHasCredit: state => {
    return state.customer?.typeAcctAr?.toUpperCase() === 'A' ? true : false;
  },
  getUserCustomerContacts: state => {
    return state.customer?.contacts ?? [];
  },
  isCustomerContactRoleAdmin: state => {
    return state.contact?.permissions?.role === 'admin';
  },
  isCustomerExcludingGst: (state, getters) => {
    return getters.getIsLoggedIn && state.customer?.showExcGstPricing;
  },
};

export const mutations = {
  updateHydrationStatus(state, payload) {
    state.hydrationStatus = payload;
  },
  setLoginReturn(state, data) {
    if (data.step === 'company_select') {
      if (!data.companies) {
        throw new Error('companies missing!');
      }
      state.temps.companyToken = data.token;
      state.temps.companies = data.companies;
    } else {
      state.temps.twoFactorToken = data.token;
    }
  },
  setTfaReturn(state, data) {
    state.temps.companyToken = data.token;
    state.temps.companies = data.companies;
  },
  setUserDetails(state, data) {
    state.customer = data.customer;
    state.contact = data.contact;
  },
  setStatementDetails(state, data) {
    state.statement = {
      date: data.statementDate,
      dueBalance: data.dueBalance,
      thirtyDayBalance: data['30DayBalance'],
      currentBalance: data.currentBalance,
      customerId: data.customerId,
    };
  },
  setBalanceDetails(state, data) {
    state.balance = data;
  },
  clear(state) {
    state.customer = {};
    state.contact = {};
    state.statement = {};
    state.balance = {};
    state.temps = {
      companies: [],
      companyToken: {},
      twoFactorToken: {},
    };
    state.tokenExpirations = {
      refreshTokenExpires: undefined,
      accessTokenExpires: undefined,
    };
  },
  clearTokenExpirations(state) {
    state.tokenExpirations = {
      refreshTokenExpires: undefined,
      accessTokenExpires: undefined,
    };
  },
  setTokenExpirations(state, data) {
    state.tokenExpirations = {
      refreshTokenExpires: data.refreshTokenExpires,
      accessTokenExpires: data.accessTokenExpires,
    };
  },
  setForcedLogout(state, data) {
    state.forcedLogout = data;
  },
  setIsRefreshing(state, data) {
    state.isRefreshing = data;
  },
  setUserFrameworkDetails(state, data) {
    state.frameworks = data;
  },
  setCustomerSettings(state, data) {
    state.customer.showExcGstPricing = data.showExcGstPricing;
  },
};

export const actions = usersActions;
export const api = usersApi;
