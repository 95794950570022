import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=64bd5278&scoped=true&"
import script from "./ProductCard.vue?vue&type=script&lang=js&"
export * from "./ProductCard.vue?vue&type=script&lang=js&"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=64bd5278&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64bd5278",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasePricing: require('/home/node/frontend/components/product-card/BasePricing.vue').default,BdAnimatedLoadingIcon: require('/home/node/frontend/node_modules/@bowenstimber/designsystem/components/BdAnimatedLoadingIcon.vue').default,ChrButton: require('/home/node/frontend/components/includes/ChrButton.vue').default,XLink: require('/home/node/frontend/components/XLink.vue').default})
